// 分页数据接口
import {ref} from "vue";
import {TuanListData} from "@/api/tuan/list";
import {request as axios} from "@/util/request";
import {initOrderData, QueryParams} from "@/api/order/order-management";
import {message} from "ant-design-vue";
import {PaginationData} from "@/api/operation/customer";


// 加载列表
export function loadList<T>(remote?: PaginationData<T>): PaginationData<T> {
    remote = remote || {} as PaginationData<T>;
    return {
        current: remote.current || 0,
        detail: remote.detail || [],
        last: remote.last || 0,
        limit: remote.limit || 0,
        total: remote.total || 0,
    }
}

export function paginationOption<T>(remote: PaginationData<T>) {
    return {
        current: +remote.current || 0,
        total: remote.total || 0,
        pageSize: remote.limit || 0,
        showTotal: () => `共${remote.total}条，每页${remote.limit}条`,
    };
}

