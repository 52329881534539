import { request as axios } from '@/util/request';

const ruleAPI = {
    update:'/boss/marketing/tuan/activity/rule/0',
    detail:'/boss/marketing/tuan/activity/rule'
}



export async function getDetail() {
    return axios.get(ruleAPI.detail);
}

export async function updateRule(params){
    return axios.put(ruleAPI.update, params);
}
